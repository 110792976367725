import { Translations } from "./translations";

export const emptyTranslations: Translations = {
  "title.my_account": "",
  "title.dashboard": "",
  "title.blocked_ips": "",
  "title.maintenance_mode": "",
  "title.users": "",
  "title.mmaz": "",
  "title.mmdz": "",
  "title.mmpz": "",
  "title.event_logger": "",
  "heading.personal_settings": "",
  "heading.user_access": "",
  "heading.applications": "",
  "heading.applications_access": "",
  "heading.user_role": "",
  "heading.status": "",
  "heading.change_password": "",
  "heading.preferences": "",
  "heading.forgot_password": "",
  "heading.setup_password": "",
  "heading.down_for_maintenance": "",
  "heading.update_email_title": "",
  "heading.block_ip": "",
  "heading.2fa": "",
  "heading.user": "",
  "heading.admin": "",
  "heading.active": "",
  "heading.disabled": "",
  "heading.closed": "",
  "heading.activation_email": "",
  "label.email": "",
  "label.current_email": "",
  "label.new_email": "",
  "label.first_name": "",
  "label.last_name": "",
  "label.country": "",
  "label.select_country": "",
  "label.timezone": "",
  "label.select_timezone": "",
  "label.language": "",
  "label.select_language": "",
  "label.last_activity": "",
  "label.first_signup": "",
  "label.current_password": "",
  "label.new_password": "",
  "label.repeat_new_password": "",
  "label.report_export_encoding": "",
  "label.signed_up_to_mailing_list": "",
  "label.mailing_list": "",
  "label.terms_of_use": "",
  "label.privacy_policy": "",
  "label.newsletter_opt_in": "",
  "label.marketing_permissions": "",
  "label.distribution_terms_and_conditions": "",
  "label.streaming_manipulation_policy": "",
  "label.your_email": "",
  "label.ip_address": "",
  "label.mmdz_access": "",
  "label.mmpz_access": "",
  "label.product_price_currency": "",
  "label.reset_password": "",
  "label.my_first_name": "",
  "label.my_last_name": "",
  "label.my_email": "",
  "label.existing_account": "",
  "label.confirm_delete": "",
  "label.registration_agreement": "",
  "label.copyright": "",
  "label.password": "",
  "label.no_activation_email": "",
  "label.repeat_password": "",
  "label.will_suspend_at": "",
  "label.yes": "",
  "label.no": "",
  "button.save": "",
  "button.reset_link": "",
  "button.resend_activation_email": "",
  "button.change": "",
  "button.forgot_password": "",
  "button.sign_up": "",
  "button.continue": "",
  "button.block_ip": "",
  "button.disable_access": "",
  "button.enable_access": "",
  "button.delete_user": "",
  "button.reset_password": "",
  "button.register": "",
  "button.log_in": "",
  "button.confirm_delete": "",
  "button.cancel": "",
  "button.delete": "",
  "button.close": "",
  "button.change_email": "",
  "button.update_email_confirmation": "",
  "button.help": "",
  "button.logout": "",
  "button.resend_email": "",
  "button.unblock": "",
  "button.activate": "",
  "button.deactivate": "",
  "button.please_wait": "",
  "button.activate_your_account": "",
  "button.give_access": "",
  "button.grant_access": "",
  "button.revoke_access": "",
  "help.first_signup": "",
  "help.password": "",
  "help.report_encoding": "",
  "help.no_account": "",
  "help.maintenance_mode": "",
  "help.access_enabled": "",
  "help.access_disabled": "",
  "help.confirm_delete": "",
  "help.change_password_description": "",
  "message.signups_exceeded": "",
  "message.down_for_maintenance": "",
  "message.maintenance_mode_on": "",
  "message.maintenance_mode_off": "",
  "message.event_logger": "",
  "email_content.dear": "",
  "email_content.registration": "",
  "email_content.mistaken_send": "",
  "modal_title.profile_picture": "",
  "modal_title.confirm_delete": "",
  "modal_description.profile_picture": "",
  "modal_description.confirm_delete": "",
  "table_field.location": "",
  "table_field.last_login_at": "",
  "table_field.created_at": "",
  "table_field.apps": "",
  "table_field.status": "",
  "table_field.ip_address": "",
  "table_field.blocked_by": "",
  "table_field.blocked_at": "",
  "table_field.actions": "",
  "table_field.picture": "",
  "flash_message.personal_settings_updated": "",
  "flash_message.given_password_invalid": "",
  "flash_message.password_format_invalid": "",
  "flash_message.choose_new_password": "",
  "flash_message.password_updated": "",
  "flash_message.preferences_updated": "",
  "flash_message.suspended_profile": "",
  "flash_message.2fa_invalid": "",
  "flash_message.2fa_updated": "",
  "2fa_content.install_app": "",
  "2fa_content.open_app": "",
  "2fa_content.scan_code": "",
  "2fa_content.add_account": "",
  "2fa_content.enter_code": "",
  "2fa_content.confirm_code": "",
  "2fa_content.additional_security": "",
  "2fa_content.activated": "",
  "2fa_content.enter_code_to_disable": "",
  "security.invalid_credentials": "",
  "security.invalid_csrf_token": "",
  "security.account_suspended": "",
  "security.account_deleted": "",
  "security.account_not_active_by_url": "",
  "security.activation_token_error": "",
  "user.grant_application_access_confirmation": "",
  "user.revoke_application_access_confirmation": "",
  invalid_credentials: "",
  invalid_csrf_token: "",
  account_suspended: "",
  account_not_active_by_url: "",
  activation_token_error: "",
};
